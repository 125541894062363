import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>About Us</h2>
              {props.data
                      ? props.data.paragraphs.map((paragraph, i) => (
                        <p>{paragraph}</p>
                      )): "loading..."}
              <h3>ADVANTAGES</h3>
              <div className="list-style">
                <div className="">
                  <ul>
                    {props.data
                      ? props.data.Why.map((advantage, i) => (
                          <li key={`${advantage.bullet}-${i}`}>
                            <span style={{ fontWeight: 'bold' }}>{advantage.bullet}</span>
                            <span>: </span>
                            <span>{advantage.description}</span>
                            </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
